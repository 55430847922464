/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {




  /**
   * Check for mobile devices
   * @return {Boolean} true if mobile
   */
  function isMobileDevice() {
    console.log('Mobile');
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }




  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        /** Loading
         ****************/
        var done_img = 0,
            percentage = 0;
        //var item = $('.preload')[0];
        var wrapper = $('.wrapper')[0];
        /*var revealer = new RevealFx( wrapper );
        var onCompleteLoad = function (contentEl, revealerEl){
          //console.log('onCompleteLoad', contentEl);
          //console.log('onCompleteLoad', revealerEl);
          revealerEl.style.opacity = 0;
          contentEl.style.opacity = 1;
        };
        var onCoverLoad = function (contentEl, revealerEl) {
          console.log('onCoverLoad', contentEl);
          contentEl.style.opacity = 1;
        };
        var onStartLoad = function (contentEl, revealerEl){
          console.log('onStartLoad', contentEl);
          contentEl.style.opacity = 0;
        };*/

        // Dalesandro imagesloaded
        $('body').imagesLoaded( { background: true } )
        .always( function( instance ) {
          //console.log('ALWAYS: all images loaded');
          $('body').toggleClass('loading');
        })
        .done( function( instance ) {
          //console.log('DONE: all images successfully loaded');
          /*revealer.reveal({
            autoStart: true,
            bgcolor: '#333',
            duration: 500,
            direction: 'tb',
            easing: 'easeInOutQuint',
            delay: 0,
            onComplete: onCompleteLoad,
            onCover: onCoverLoad,
            onStart: onStartLoad
          });*/
        })
        .fail( function() {
          console.log('FAIL: all images loaded, at least one is broken');
        })
        .progress( function( instance, image ) {
          var result = image.isLoaded ? 'loaded' : 'broken';
          //console.log( 'PROGRESS: image is ' + result + ' for ' + image.img.src );
          total_img = instance.images.length;
          //console.log(instance.images.length);
          done_img++;
          percentage = ((done_img / total_img) * 100);
          //console.log( Math.floor(percentage) + ' %');
          $('.percent').html( Math.floor(percentage) + ' %');
          $('.percent').css( 'opacity', 1 - (percentage / 100) );
        });





        /*** Efecto fotos
         ******************/
        var onCover = function (contentEl, revealerEl) {
          // console.log('onCover', contentEl);
          contentEl.style.opacity = 1;
        };
        var onStart = function (contentEl, revealerEl){
          // console.log('onStart', contentEl);
          contentEl.style.opacity = 0;
        };

        // Responsive offset
        var offset = -200;
        if (document.documentElement.clientWidth < 620) {
          offset = -150;
        }

        var pics_home_settings = {
          bgcolor: '#dddddd',
          duration: 600,
          autoStart: false,
          direction: 'tb',
          offset: -200,
          easing: 'easeInOutQuint',
          delay: 0,
          onCover: onCover,
          onStart: onStart
        };
        var title_settings = {
          bgcolor: '#333',
          duration: 400,
          autoStart: false,
          direction: 'rl',
          offset: offset,
          easing: 'easeInOutCubic',
          delay: 0,
          onCover: onCover,
          onStart: onStart
        };
        var pics_single_settings = {
          bgcolor: '#dddddd',
          duration: 600,
          autoStart: false,
          direction: 'lr',
          offset: -200,
          easing: 'easeInOutQuint',
          delay: 0,
          onCover: onCover,
          onStart: onStart
        };
        //console.log('pics_home_settings', pics_home_settings.offset);
        // var $pics = $('.proyectos img');
        var $pics = $('.proyectos .image-content');
        var $pics_single = $('.single-proyecto .pics li');
        var $titles = $('.proyectos .proyecto-item h2');
        // console.log($pics);
        // console.log($titles);
        // console.log(scrollMonitor);
        // console.log(RevealFx);
        // console.log(anime);
        //console.log(anime.easings);

        $pics.each( function( index ) {
          //console.log('index', index);
          //console.log('item', $(this));
          var item = $(this)[0];
          // console.log( 'Item:', item );
          var revealer = new RevealFx(item);
          var watcher = scrollMonitor.create(item, pics_home_settings.offset);
          watcher.enterViewport( function(e, el) {
            // console.log( 'I have entered the viewport:', el.watchItem);
            revealer.reveal(pics_home_settings);
          });
          watcher.exitViewport( function(e, el) {
            // console.log( '---> I have leave the viewport.', el.watchItem);
            this.destroy();
            revealer = null;
            // el.watchItem.style.opacity = 0;
          });
        });

        $titles.each( function( index ) {
          //console.log('index', index);
          //console.log('item', $(this));
          var item = $(this)[0];
          // console.log( 'Item:', item );
          var revealer = new RevealFx(item);
          var watcher = scrollMonitor.create(item, title_settings.offset);
          watcher.enterViewport( function(e, el) {
            // console.log( 'I have entered the viewport:', el.watchItem);
            revealer.reveal(title_settings);
          });
          watcher.exitViewport( function(e, el) {
            // console.log( '---> I have leave the viewport.', el.watchItem);
            this.destroy();
            revealer = null;
            // el.watchItem.style.opacity = 0;

          });
        });

        $pics_single.each( function( index ) {
          //console.log('index', index);
          //console.log('item', $(this));
          var item = $(this)[0];
          // console.log( 'Item:', item );
          var revealer = new RevealFx(item);
          var watcher = scrollMonitor.create(item, pics_single_settings.offset);
          watcher.enterViewport( function(e, el) {
            // console.log( 'I have entered the viewport:', el.watchItem);
            revealer.reveal(pics_single_settings);
          });
          watcher.exitViewport( function(e, el) {
            // console.log( '---> I have leave the viewport.', el.watchItem);
            this.destroy();
            revealer = null;
            // el.watchItem.style.opacity = 0;
          });
        });




        /*** Menu principal
         ******************/
        $('a.navigation-menu-button', 'body').on('click', function(){
          // console.log('navigation-menu-button');
          $('body').toggleClass('showmenu');
        });
        // Navegación fuera
        /*$('.menu-item a').on('click', function(){
          // console.log('menu-item');
          $('body').toggleClass('loading');
        });
        $('.arrowNav a').on('click', function(){
          // console.log('menu-item');
          $('body').toggleClass('loading');
        });*/


        // Menu Principal Fixed con scroll
        var nav = $('body');
        var top = 140;
        if( ! isMobileDevice() ){
          $(window).scroll(function () {
            if ($(this).scrollTop() > top) {
              nav.addClass("f-nav");
            } else {
              nav.removeClass("f-nav");
            }
          });
        }


        /*** Menu horizontal proyectos
         ******************/
        $('.arrowNav > div').on('mouseover', function(){
          $('a', this).addClass('show_pict');
          return false;
        }).on('click', function(){
          //$('body').addClass('loading');
        });
        $('.arrowNav > div').on('mouseout', function(){
          $('a', this).removeClass('show_pict');
          return false;
        }).on('click', function(){
          //$('body').addClass('loading');
        });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
